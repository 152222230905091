export default class MatchTemplate {
  number = 0
  home = null
  homes = []
  aways = []
  ref = null
  homeFeeder = null
  homeFeedWinner = false
  awayFeeder = null
  awayFeedWinner = false
  refFeeder = null
  refFeedWinner = false

  constructor (h, a, r) {
    if (h) {
      if (h.includes && h.includes('-')) {
        this.homes = h.split('-').map(m => +m)
      } else {
        this.home = +h
      }
    }
    if (a) {
      if (a.includes && a.includes('-')) {
        this.aways = a.split('-').map(m => +m)
      } else {
        this.away = +a
      }
    }
    this.ref = r
  }

  get used () {
    return [...this.homes, ...this.aways]
  }

  get kobHome () {
    return this.homes.join('-')
  }

  get kobAway () {
    return this.aways.join('-')
  }
}
